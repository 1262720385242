<script>
  import FileSaver from "file-saver";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "fontawesome-svelte";
  library.add(faCloudArrowDown);

  const defaultFilename = "untitled.txt";
  let filename = "";
  let content = "";
  let editsMade = false;

  function handleSubmit() {
    FileSaver.saveAs(
      new Blob([content], { type: "text/plain;charset=utf-8" }),
      filename || 'untitled'
    );
  }

  function stopIfUnsavedChanges(event) {
    if (editsMade) {
      const userWantsToLeave = confirm(
        "Are you sure you want to refresh this page? You will lose any changes you have made."
      );
      if (!userWantsToLeave) {
        event.preventDefault();
      }
    }
  }

  const codeFieldProps = {
    spellcheck: 'false',
    autocapitalize: 'off',
    autocomplete: 'off',
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600;700&family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet">
</svelte:head>

<header>
  <a href="/" title="Start new file" on:click={stopIfUnsavedChanges}>
    <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70 40" style="enable-background:new 0 0 70 40;" xml:space="preserve">
      <g>
        <polygon points="6.11,16.61 6.11,18.92 1.5,18.92 1.5,21.09 6.11,21.09 6.11,25.7 8.29,25.7 8.29,21.09 12.9,21.09 12.9,18.92 
          8.29,18.92 8.29,14.3 6.11,14.3 6.11,16.61 "/>
        <path d="M23.03,1.21h2.37v34.61c0,0.57-0.14,1.1-0.43,1.58c-0.28,0.49-0.67,0.87-1.16,1.16
          C23.32,38.86,22.81,39,22.28,39l0,0c-0.9,0-1.65-0.31-2.26-0.94c-0.61-0.63-0.92-1.37-0.92-2.24l0,0V3.96c0-0.14-0.03-0.28-0.1-0.4
          c-0.07-0.13-0.16-0.23-0.27-0.3c-0.11-0.07-0.23-0.1-0.37-0.1l0,0c-0.22,0-0.4,0.08-0.54,0.24c-0.14,0.16-0.22,0.35-0.22,0.57l0,0
          v34.82h-2.42V4.12c0-0.39,0.09-0.79,0.27-1.18c0.18-0.39,0.4-0.74,0.67-1.02c0.27-0.29,0.6-0.51,0.99-0.67
          C17.51,1.08,17.92,1,18.35,1l0,0c0.86,0,1.59,0.3,2.2,0.91c0.61,0.61,0.91,1.34,0.91,2.21l0,0v31.92c0,0.21,0.08,0.39,0.25,0.54
          c0.16,0.14,0.34,0.22,0.54,0.22c0.19,0,0.37-0.07,0.54-0.22c0.16-0.14,0.24-0.32,0.24-0.54l0,0V1.21L23.03,1.21z M30.83,36.63
          h-1.56l0.05-15.56h3.88v-2.15h-3.93V3.36h1.56v13.99h2.37V1.21h-6.3v37.57h6.3V22.64h-2.37V36.63L30.83,36.63z M37.93,39L37.93,39
          c-0.57,0-1.1-0.14-1.58-0.43c-0.48-0.28-0.87-0.67-1.16-1.16c-0.28-0.48-0.43-1.01-0.43-1.58l0,0V1.21h2.36v34.83
          c0,0.21,0.08,0.39,0.25,0.54c0.16,0.14,0.35,0.22,0.56,0.22c0.22,0,0.4-0.07,0.54-0.22c0.14-0.14,0.22-0.32,0.22-0.54l0,0V1.21
          h2.37v34.83c0,0.21,0.08,0.39,0.24,0.54c0.16,0.14,0.34,0.22,0.54,0.22c0.2,0,0.38-0.07,0.54-0.22c0.16-0.14,0.25-0.32,0.25-0.54
          l0,0V1.21h2.37v34.61c0,0.57-0.14,1.1-0.43,1.58c-0.29,0.49-0.68,0.87-1.16,1.16C42.91,38.86,42.4,39,41.86,39l0,0
          c-0.75,0-1.42-0.23-1.99-0.7l0,0C39.3,38.77,38.65,39,37.93,39L37.93,39z M52.79,17.36h-2.37V3.36h-1.56l0.05,15.56h3.88v2.15
          h-3.93v17.71h-2.37V1.21h6.3V17.36L52.79,17.36z M54.34,1.21h2.42v37.57h-2.42V1.21L54.34,1.21z M58.28,1.21v37.57h4.36v-2.15
          h-1.99V1.21H58.28L58.28,1.21z M66.13,36.63h-1.56l0.06-15.56h3.87v-2.15h-3.93V3.36h1.56v13.99h2.37V1.21h-6.29v37.57h6.29V22.64
          h-2.37V36.63L66.13,36.63z"/>
      </g>
    </svg>
  </a>
  <p>New File.<br />It’s that simple.</p>
</header>

<main>
  <input {...codeFieldProps} type="text" placeholder="untitled.txt" bind:value={filename} on:input={() => editsMade = true} />
  <textarea {...codeFieldProps} id="content" placeholder="Write your content here..." bind:value={content} on:input={() => editsMade = true}></textarea>
  <button type="submit" on:click={handleSubmit}>Save <FontAwesomeIcon icon={faCloudArrowDown} size="lg" />
</button>
</main>

<style>
  :global(:root) {
    --primary: #1426d5;
    --sans: "Poppins", sans-serif;
    --mono: "Inconsolata", monospace;
    --space: 7px;
    --field-padding: 10px;
    --rounding: 4px;
  }
  :global(:is(html, body)) {
    margin: 0;
    padding: 0;
  }
  :global(:is(html)) {
    height: 100%;
    box-sizing: border-box;
  }
  :global(:is(html *, html *::before, html *::after)) {
    box-sizing: inherit;
  }
  :global(body) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #111;
    min-height: 100%;
    font-family: var(--sans);
    -webkit-font-smoothing: antialiased;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px var(--space);
    background: #fff;
    user-select: none;
  }
  header > a {
    display: flex;
  }
  #logo {
    height: 47px;
    width: auto;
  }
  #logo path {
    fill: var(--primary);
  }
  header > p {
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #777;
    text-align: right;
  }
  header > p::first-line {
    font-weight: 600;
    color: #222;
  }
  main {
    flex: 1;
    width: 100%;
    padding: var(--space);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: var(--space);
  }
  input {
    padding: var(--field-padding);
    appearance: none;
    border: none;
    width: 100%;
    border-radius: var(--rounding);
    font-family: var(--mono);
    font-size: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  input::-webkit-scrollbar {
    display: none;
  }
  textarea {
    flex: 1;
    appearance: none;
    resize: none;
    border: none;
    width: 100%;
    padding: var(--field-padding);
    white-space: pre;
    border-radius: var(--rounding);
    font-family: var(--mono);
    font-size: 16px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
  button {
    appearance: none;
    resize: none;
    border: none;
    padding: var(--field-padding);
    background-color: var(--primary);
    border-radius: var(--rounding);
    font-family: var(--sans);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }
</style>